.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav {
  bottom: 0px;
  padding: 20px;
  text-align: center;
  color: #000;
  border: 1px solid;
  font-size: calc(10px + 1vmin);
}

.bannerdiv img {
  width: 100%;
}

.container {
  padding: 20px 16%;
}
.bannerH1 {
  font-size: 46px;
  line-height: 1;
  color: #000000;
  text-align: center !important;
}

.bannerH1 .bannerTextPrimary {
  color: #d8877f;
  text-align: center !important;
}

.bannerTextParagraph {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
}
.homeContainer {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.privacySpan {
  font-weight: bold;
}
.bannerImg {
  width: 100%;
}
.container-fluid {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  background: #ccc;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.c3 {
  flex: 0 0 auto;
  width: 33%;
}
.c12 {
  flex: 0 0 auto;
  width: 100%;
}
.textcenter {
  text-align: center;
}
.btn {
  padding: 8px 20px;
  background-color: #000000;
  border: none;
  color: #fff;
  font-size: 16px;

  background-position: right bottom;
}

.btn:hover {
  background: linear-gradient(to right, #3b3b3b 50%, #000 50%);
  transition: all 0.5s ease-out;
  background-size: 200% 100%;
  background-position: left bottom;
}
.card {
  padding: 20px;
}
.cardinner {
  border: 1px solid #ccc;
  padding: 15px 0px;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.cardtext {
  display: block;
  text-align: justify;
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.cardtext h2 {
  text-align: center;
}
.headingtxt::after {
  display: block;
  clear: both;
  content: "";
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 250px;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 1px solid #e2000f;
  margin: 0 auto;
  padding: 8px 0px;
}
.logo {
  width: 25%;
}

.desktoponly {
  display: flex;
}
.mobileonly {
  display: none;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo {
    width: 60%;
  }
  .bannerH1 {
    font-size: 34px;
    line-height: 1;
    color: #000000;
    text-align: center !important;
  }
  .container {
    padding: 10px 16%;
  }
  .c3 {
    width: 50%;
  }
  .desktoponly {
    display: none;
  }
  .mobileonly {
    display: block;
    width: 100%;
  }
  .mc1 {
    flex: 0 0 auto;
    width: 20%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  ...;
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  ...;
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  ...;
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  ...;
} */
